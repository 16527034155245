//used for cladding.  Z0 values map to a matching wind tunnel profile.  There is no "Skyscraper" wind tunnel profile for cladding
export const WINDTUNNELPROFILELIST = [
  { value: 0.007, label: 'Open Water'},
  { value: 0.038, label: 'Open'},
  { value: 0.08, label: 'Rural'},
  { value: 0.24, label: 'Forest/Suburban'},
  { value: 1.4, label: 'Urban'}
];

//used for PLW.  Z0 values are used to generate a log-law profile
export const PLWROUGHNESSLIST = [
  { value: 0.005, label: 'Open Water'},
  { value: 0.03, label: 'Open'},
  { value: 0.1, label: 'Rural' },
  { value: 0.3, label: 'Forest/Suburban' },
  { value: 0.5, label: 'Light Urban' },
  { value: 1, label: 'Urban' },
  { value: 3, label: 'Skyscrapers'}
];

export const DEFAULT_CLADDING_H_REF = 609;
export const DEFAULT_CLADDING_ROUGHNESS = 0.24;

export const DEFAULT_PLW_H_REF = 60;
export const DEFAULT_PLW_ROUGHNESS = 0.5;